import { BinInfo } from '@/types/liquidity';
import { d, decimalsMultiplier } from '@/utils';
import {
  fetchLiquidityByBinId,
  getAmountsOutFromShares,
} from '@/utils/liquidityApi';

export function useFetchBinAmounts() {
  async function fetchBinAmounts(
    fromToken: string,
    toToken: string,
    binStep: number,
    items: BinInfo[],
    decimalsX: number,
    decimalsY: number,
  ) {
    const binAmounts = Array.from(items);

    console.log('PLEASE 1', binAmounts, items, fromToken, toToken, binStep);

    try {
      const binsReserves = await Promise.all(
        binAmounts.map((item: any) =>
          fetchLiquidityByBinId(fromToken, toToken, binStep, Number(item.name)),
        ),
      );

      console.log('PLEASE', binsReserves);

      binsReserves.forEach((reserves, idx) => {
        if (!reserves) {
          return;
        }

        const [amountXOut, amountYOut] = getAmountsOutFromShares(
          Number(reserves[0]),
          Number(reserves[1]),
          binAmounts[idx].amount,
          binAmounts[idx].supply,
        );
        console.log('PLEASE', { reserves, idx, amountXOut, amountYOut });

        binAmounts[idx].amountXOut = +d(amountXOut).div(
          decimalsMultiplier(decimalsX),
        );

        binAmounts[idx].amountYOut = +d(amountYOut).div(
          decimalsMultiplier(decimalsY),
        );
      });
    } catch (error) {
      console.error('PLEASE', error);
    }

    return binAmounts;
  }

  return { fetchBinAmounts };
}
