import {
  createGlobalState,
  StorageSerializers,
  useStorage,
} from '@vueuse/core';
import { computed, reactive, readonly, ref, watch } from 'vue';
import {
  APTOS,
  APTOS_TESTNET_CHAIN_ID,
  CACHE_NAME,
  ChainsEnum,
  CORRECT_CHAIN,
  NATIVE_COIN,
} from '@/constants';
import { CORRECT_CHAIN_ID, Network, NETWORKS } from '@/constants/networks';
import { useAptosClient } from '@/composables/useAptosClient';
import { restUrl } from '@/utils/networkData';
import { useDialogStore } from './useDialogStore';
import { DynamicDialogInstance } from 'primevue/dynamicdialogoptions';
import { MSafeWallet } from '@msafe/aptos-wallet';
import { walletsList } from '@/libs/adapter/wallets';
import { NetworkName, useWalletPlugin } from '@/composables/useWalletPlugin';

type GlobalCachebleState = {
  account?: { address: string; type: string };
  defaultToken: string;
  reset: boolean;
  techWork: number;
  isMobile: boolean;
};

const DAY_IN_MS = 86400000;

const handleMobileScreen = () => {
  const mediaQueryList = window.matchMedia('screen and (max-width: 767px)');
  return mediaQueryList.matches;
};

export const useStore = createGlobalState(() => {
  const dialogStore = useDialogStore();
  let dialogRef: DynamicDialogInstance;

  const isL2 = computed(() => CORRECT_CHAIN.id === ChainsEnum['lumio-testnet']);
  const referralId = ref('');
  const referralIdLoading = ref(true);

  const storage = useStorage<GlobalCachebleState>(
    CACHE_NAME,
    {
      account: undefined,
      defaultToken: isL2.value ? NATIVE_COIN : APTOS,
      reset: false,
      techWork: 0,
      isMobile: handleMobileScreen(),
    },
    undefined,
    { serializer: StorageSerializers.object },
  );
  const networkId = ref(CORRECT_CHAIN_ID);

  const showTermsAndConditions = useStorage('showTermsAndConditions', true);
  const showDownloadWalletBanner = useStorage('showDownloadWalletBanner', true);
  const showWelcomeL2Dialog = useStorage('showWelcomeL2Dialog', isL2.value);
  const account = computed(() => storage.value.account);
  const isMobile = computed(() => storage.value.isMobile);
  const showOnBoarding = useStorage('showOnBoarding', true);
  const network = computed(
    () =>
      networks.find((network: Network) => {
        return network.id === networkId.value;
      }) as Network,
  );
  const defaultToken = computed(() => storage.value.defaultToken);

  /**
   * Show Tech Work Dialog after loading
   *
   * @returns true one time on every Thursday, otherwise false
   */
  const showTechWorkDay = (): boolean => {
    const today = new Date();
    const shown = storage.value.techWork;
    // INFO: Show only on Thursday from 1:30 till 9:30 UTC once a day
    if (
      today.getDay() === 4 &&
      today.getUTCHours() >= 1 &&
      today.getUTCMinutes() >= 30 &&
      today.getUTCHours() <= 9 &&
      today.getUTCMinutes() <= 30 &&
      (shown === 0 || (shown && +today - shown > DAY_IN_MS))
    ) {
      storage.value.techWork = +today;
      return true;
    }
    return false;
  };

  const networks = reactive(NETWORKS);
  const {
    account: walletAccount,
    network: walletNetwork,
    wallet,
    connect,
  } = useWalletPlugin();

  /**
   * use setTimeout to wait for the initialization of the adapter from main.ts (300ms)
   */
  setTimeout(connectMSafeWallet, 500);

  async function connectMSafeWallet() {
    if (MSafeWallet.inMSafeWallet()) {
      try {
        const mSafeWallet = walletsList.find((item) => item.key === 'MSafe');
        if (!mSafeWallet?.key) return;

        connect('MSafe');
      } catch (err) {
        console.error('err', err);
      }
    }
  }

  const walletAddress = computed(() => walletAccount?.value?.address);
  const chainId = computed(
    () => walletNetwork.value?.chainId || `${CORRECT_CHAIN_ID}`,
  );
  const name = computed(() => wallet.value?.name || '');

  function resetAccount() {
    const aptos = useAptosClient();
    storage.value.defaultToken = isL2.value ? NATIVE_COIN : APTOS;
    if (walletAddress.value) {
      if (name.value.toLowerCase() !== 'pontem') {
        // other wallets like Petra || Martian || rise || fewcha etc...
        if (
          walletNetwork.value.name.toLowerCase() ===
          NetworkName.Mainnet.toLowerCase()
        ) {
          networkId.value = CORRECT_CHAIN_ID;
          aptos.changeConfig(restUrl(`${CORRECT_CHAIN_ID}`));
        } else if (
          walletNetwork.value.name
            .toLowerCase()
            .indexOf(NetworkName.Testnet) !== -1
        ) {
          networkId.value = APTOS_TESTNET_CHAIN_ID;
          aptos.changeConfig(restUrl(`${APTOS_TESTNET_CHAIN_ID}`));
        } else {
          networkId.value = 0;
        }
      } else {
        // Pontem wallet
        if (
          walletNetwork.value.name
            .toLowerCase()
            .indexOf(NetworkName.Testnet) !== -1
        ) {
          networkId.value = APTOS_TESTNET_CHAIN_ID;
          aptos.changeConfig(restUrl(`${APTOS_TESTNET_CHAIN_ID}`));
        } else if (
          chainId.value !== undefined &&
          !isNaN(parseInt(chainId.value)) &&
          networkId.value !== parseInt(chainId.value)
        ) {
          networkId.value = parseInt(chainId.value);
          if (parseInt(chainId.value) === CORRECT_CHAIN_ID) {
            aptos.changeConfig(restUrl(chainId.value));
          }
        }
      }
      if (networkId.value === CORRECT_CHAIN_ID) {
        if (dialogRef) dialogRef.close();
      }
      storage.value.account = {
        address: walletAddress.value as string,
        type: `${name.value}`,
      };
      storage.value.defaultToken = isL2.value ? NATIVE_COIN : APTOS;
    } else {
      dialogs.invalidNetwork = false;
      storage.value.account = undefined;
    }
  }

  watch([walletAddress, walletNetwork, chainId, name], () => {
    dialogStore.resetDialogState();
    resetAccount();
  });

  function showDialog(name: string) {
    dialogs[name] = true;
  }

  window.addEventListener('resize', () => {
    storage.value.isMobile = handleMobileScreen();
  });

  const dialogs = reactive<Record<string, boolean>>({
    acceptTermsAndConditions: showTermsAndConditions.value,
    accountsList: false,
    addLiquidityConfirm: false,
    burnLiquidityConfirm: false,
    coinList: false,
    connectWallet: false,
    createPoolConfirm: false,
    invalidNetwork: false,
    noFundsAvailable: false,
    notifiCard: false,
    requestTokens: false,
    swapConfirm: false,
    techWork: showTechWorkDay(),
  });

  return {
    networkId: readonly(networkId),
    network,
    defaultToken,
    account,
    dialogs,
    showDialog,
    showOnBoarding,
    showTermsAndConditions,
    showDownloadWalletBanner,
    showWelcomeL2Dialog,
    isMobile,
    referralId,
    referralIdLoading,
    walletName: readonly(name),
  };
});
