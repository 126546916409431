import { defineStore } from 'pinia';
import { computed, reactive, Ref, ref, watch } from 'vue';
import { d, decimalsMultiplier } from '@/utils';
import { useStore, useTokensStore } from '@/store';
import { tryOnMounted, watchDebounced } from '@vueuse/core';
import { useAptosClient } from '@/composables/useAptosClient';
import {
  calcReceivedLP,
  composeType,
  getCurve,
  getOptimalLiquidityAmount,
  getResourcesAccount,
  getShortCurveFromFull,
  is_sorted,
  isComplexCurve,
  lpTokenNameStr,
  withSlippage,
} from '@/utils/contracts';
import { getFromCache } from '@/utils/cache';
import { usePoolExistence } from '@/composables/usePoolExistence';
import { IStoredToken } from '@/types';
import { moduleAddress } from '@/utils/networkData';
import { getPoolStr } from '@/utils/pools';
import { useContractVersion } from '@/composables/useContractVersion';
import { checkIsPoolAreExceptional } from '@/composables/checkIsPoolAreExceptional';
import { VERSION_0, VERSION_0_5, VERSION_1, VERSION_SOLO } from '@/constants';
import { IPersistedPool } from '@/types/pools';

const DEFAULT_SLIPPAGE = 0.005;

export const useAddLiquidityStore = defineStore('addLiquidityStore', () => {
  const { version, setVersion: setContractVersion } = useContractVersion();
  const from = reactive<Omit<IStoredToken, 'decimals'>>({
    token: undefined,
    amount: undefined,
    reserve: 0,
  });

  const to = reactive<Omit<IStoredToken, 'decimals'>>({
    token: undefined,
    amount: undefined,
    reserve: 0,
  });

  const curve = ref<string>(
    version.value !== VERSION_SOLO ? getCurve('unstable', version.value) : '',
  );
  const poolExistence = usePoolExistence();
  if (version.value !== VERSION_SOLO) {
    poolExistence.watch(from, to, curve, version as Ref<number>);
  }

  const slippageIsDefault = ref(true);
  const slippage = ref(DEFAULT_SLIPPAGE);
  const convertRate = ref(0);
  const interactiveField = ref<'from' | 'to'>('from');
  const lastInteractiveField = ref<'from' | 'to'>('from');
  const isUpdatingRate = ref(false);
  const isSorted = ref(false);
  const lpSupply = ref(0);
  const pool = ref<IPersistedPool | null>(null);

  const mainStore = useStore();
  const tokensStore = useTokensStore();
  const aptos = useAptosClient();

  const receiveLp = computed<number | undefined>(() => {
    if (!from.token || !to.token) {
      return;
    }

    const value = calcReceivedLP({
      x: withSlippage(
        slippage.value,
        isSorted.value ? (from.amount as number) : (to.amount as number),
      ),
      y: withSlippage(
        slippage.value,
        isSorted.value ? (to.amount as number) : (from.amount as number),
      ),
      xReserve: isSorted.value ? from.reserve : to.reserve,
      yReserve: isSorted.value ? to.reserve : from.reserve,
      lpSupply: +lpSupply.value,
    });

    if (value <= 0) {
      return;
    }

    return value;
  });

  const isFetching = computed(() => {
    if (!from.token || !to.token || version.value === VERSION_SOLO) {
      return false;
    }
    return poolExistence.isFetching(
      {
        fromCoin: from.token,
        toCoin: to.token,
        curve: curve.value,
      },
      version.value,
    );
  });

  const isPoolExists = computed(() => {
    if (!from.token || !to.token || version.value === VERSION_SOLO) {
      return false;
    }
    return (
      poolExistence.poolExists(
        {
          fromCoin: from.token,
          toCoin: to.token,
          curve: curve.value,
        },
        version.value,
      ) || Boolean(pool.value)
    );
  });

  const isPoolAbsence = computed(() => isFetching.value && !isPoolExists.value);

  const lpTokenName = computed(() => {
    if (version.value === VERSION_SOLO) return 'LP';
    return lpTokenNameStr(
      {
        tokens: [from.token, to.token],
        curve: curve.value,
      },
      version.value,
    );
  });

  /**
   * Checks whether the selected pool is exceptional or not
   */
  const isPoolExceptional = computed((): boolean => {
    if (!from.token || !to.token) {
      return false;
    }

    return checkIsPoolAreExceptional(from.token, to.token);
  });

  watch(slippageIsDefault, (value) => {
    if (value) {
      slippage.value = DEFAULT_SLIPPAGE;
    }
  });

  tryOnMounted(() => resetState());

  watch([tokensStore.tokens], () => {
    from.token =
      tokensStore.getToken(from.token)?.type || mainStore.defaultToken.value;
    to.token =
      from.token !== tokensStore.getToken(to.token)?.type
        ? tokensStore.getToken(to.token)?.type
        : undefined;
  });

  function resetState() {
    setContractVersion(0);
    from.token = mainStore.defaultToken.value;
    to.token = undefined;
    from.amount = undefined;
    to.amount = undefined;
    from.reserve = 0;
    to.reserve = 0;
  }

  async function refetchRates(silent = false) {
    if (from.token && to.token && version.value !== VERSION_SOLO) {
      lastInteractiveField.value = interactiveField.value;
      if (!silent) {
        isUpdatingRate.value = true;
      }
      const mode = lastInteractiveField.value;

      isSorted.value = is_sorted(from.token, to.token);
      const [fromToken, toToken] = isSorted.value
        ? [from.token, to.token]
        : [to.token, from.token];

      const resourceType = getPoolStr(
        fromToken,
        toToken,
        curve.value,
        version.value,
      );

      const lp = lpTokenNameStr(
        {
          tokens: [fromToken, toToken],
          curve: curve.value,
        },
        version.value,
      );
      const resourceAccount = getResourcesAccount(version.value);

      const response = await getFromCache(
        ['calc', resourceAccount, resourceType].join('-'),
        () => aptos.client.getAccountResource(resourceAccount, resourceType),
        { time: 2000 },
      );

      if (!response) {
        from.reserve = 0;
        to.reserve = 0;
        isUpdatingRate.value = false;
        return;
      } else {
        const coinXReserve = +(
          response.data?.coin_x_reserve?.value || response.reserveX
        );
        const coinYReserve = +(
          response.data?.coin_y_reserve?.value || response.reserveY
        );

        from.reserve = isSorted.value ? coinXReserve : coinYReserve;
        to.reserve = isSorted.value ? coinYReserve : coinXReserve;
      }

      const optimalAmount =
        mode === 'from'
          ? getOptimalLiquidityAmount(
              from.amount as number,
              from.reserve,
              to.reserve,
            )
          : getOptimalLiquidityAmount(
              to.amount as number,
              to.reserve,
              from.reserve,
            );

      const address = mainStore.account.value?.address;
      if (address === undefined) {
        // TODO: process error
        throw new Error('Error on getting data');
      }

      const lpSupplyResponse = await getFromCache(
        ['coin-info', lp].join('-'),
        async () =>
          aptos.client.getAccountResource(
            resourceAccount,
            composeType(moduleAddress('CoinInfo'), [lp]),
          ),
        { time: 2000 },
      );

      lpSupply.value =
        lpSupplyResponse?.data.supply.vec[0].integer.vec[0].value;

      if (mode === 'from') {
        to.amount = isNaN(optimalAmount) ? to.amount : optimalAmount;
        convertRate.value = (from.amount as number) / (to.amount as number);
      } else {
        from.amount = isNaN(optimalAmount) ? from.amount : optimalAmount;
      }

      const toDecimals = tokensStore.getToken(to.token)?.decimals;
      const fromDecimals = tokensStore.getToken(from.token)?.decimals;
      if (!toDecimals || !fromDecimals) {
        throw new Error("Couldn't get decimals");
      }
      const toDec = d(to.amount).div(decimalsMultiplier(toDecimals));
      const fromDec = d(from.amount).div(decimalsMultiplier(fromDecimals));
      convertRate.value = +Number(
        toDec.div(fromDec).mul(decimalsMultiplier(toDecimals)).toFixed(0),
      );

      if (!silent) {
        isUpdatingRate.value = false;
      }
    }
  }

  async function check() {
    if (!from?.token || !to?.token || version.value === VERSION_SOLO) return;
    await poolExistence.check(
      {
        fromCoin: from.token,
        toCoin: to.token,
        curve: curve.value,
      },
      version.value,
    );
  }

  watchDebounced(
    () => [from, to, curve, version],
    async () => {
      await check();
      await refetchRates(false);
    },
    {
      debounce: 500,
      maxWait: undefined,
      deep: true,
    },
  );

  const isBusy = computed(
    () => poolExistence.isFetching || isUpdatingRate.value,
  );

  const setCurveByVersion = (
    version: typeof VERSION_0 | typeof VERSION_0_5,
  ) => {
    const shortCurveName = getShortCurveFromFull(curve.value);
    if (!shortCurveName) {
      throw new Error('Сould not get the short name of the curve');
    }

    setCurve(getCurve(shortCurveName, +version));
  };

  const poolsDataEnsured = computed(
    () => from.token && to.token && curve.value && version.value != undefined,
  );

  function setCurve(complexCurveType?: string) {
    if (!complexCurveType || !isComplexCurve(complexCurveType)) {
      console.log(`Invalid curve type ${complexCurveType}`);
      return;
    }
    curve.value = complexCurveType;
  }

  function setVersion(
    ver:
      | typeof VERSION_0
      | typeof VERSION_0_5
      | typeof VERSION_1
      | typeof VERSION_SOLO
      | undefined,
  ) {
    if (
      ver === undefined ||
      (ver !== VERSION_0 && ver !== VERSION_0_5 && ver !== VERSION_SOLO)
    ) {
      console.log(`Invalid version ${ver}`);
      return;
    }
    setContractVersion(ver);
  }

  function setPool(_pool?: IPersistedPool) {
    pool.value = _pool || null;
  }

  /**
   * Store public interface
   */
  return {
    version,

    isBusy,
    isPoolAbsence,
    exists: poolExistence.poolExists,
    convertRate,
    fromCurrency: from,
    interactiveField,
    isUpdatingRate,
    lastInteractiveField,
    slippageIsDefault,
    slippage,
    receiveLp,
    isSorted,
    toCurrency: to,
    refetchRates,
    withSlippage,
    lpTokenName,
    curve,
    setCurveByVersion,
    poolsDataEnsured,
    isPoolExceptional,
    setCurve,
    setVersion,
    setPool,
    pool,
    isFetching,
    isPoolExists,
  };
});
